import clsx from 'clsx'
import { DotLoader } from '../Loader/DotLoader'
import { ReactNode } from 'react'
import { Typograhy } from '../Typograhy'

export type PrimaryButtonProps = {
  children?: string | ReactNode
  className?: string
  dataAttr?: string
  submitting?: boolean
  onClick: () => void
}

export const PrimaryButton: React.FC<PrimaryButtonProps> = ({ className, submitting, children, onClick, dataAttr, ...rest }) => {
  return (
    <button
      data-e2e={dataAttr}
      title={String(children)}
      className={clsx(
        'ui-w-fit ui-bg-dfds-status-warning ui-cursor-pointer ui-transition ui-border-0 ui-text-dfds-text-light-primary ui-h-[44px] ui-rounded-sm hover:ui-opacity-90 active:ui-opacity-80',
        className
      )}
      onClick={onClick}
      {...rest}
    >
      {submitting ? (
        <DotLoader />
      ) : (
        <Typograhy className="ui-text-ellipsis ui-whitespace-nowrap ui-overflow-hidden" as="labelBold">
          {children}
        </Typograhy>
      )}
    </button>
  )
}
