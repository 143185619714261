'use client'

import { motion, AnimatePresence } from 'framer-motion'

const ContainerVariants = {
  initial: {
    transition: {
      staggerChildren: 0,
    },
  },
  animate: {
    transition: {
      staggerChildren: 0.2,
    },
  },
}

const DotVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: { opacity: 0 },
}

export const DotLoader = () => {
  return (
    <span>
      <AnimatePresence>
        <motion.div className="ui-w-[25px] ui-flex ui-justify-between" variants={ContainerVariants} initial="initial" animate="animate">
          <motion.span
            className="ui-block ui-w-[5px] ui-h-[5px] ui-bg-dfds-text-light-primary ui-radius ui-rounded-[50%]"
            variants={DotVariants}
            transition={{ repeat: Infinity, ease: 'easeInOut', duration: 0.8 }}
          />
          <motion.span
            className="ui-block ui-w-[5px] ui-h-[5px] ui-bg-dfds-text-light-primary ui-radius ui-rounded-[50%]"
            variants={DotVariants}
            transition={{ repeat: Infinity, ease: 'easeInOut', duration: 0.8 }}
          />
          <motion.span
            className="ui-block ui-w-[5px] ui-h-[5px] ui-bg-dfds-text-light-primary ui-radius ui-rounded-[50%]"
            variants={DotVariants}
            transition={{ repeat: Infinity, ease: 'easeInOut', duration: 0.8 }}
          />
        </motion.div>
      </AnimatePresence>
    </span>
  )
}
