import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { PublicClientApplication } from '@azure/msal-browser'
import { msalAuthSettings } from '@dfds-pax/login'

const msalInstance = new PublicClientApplication(msalAuthSettings.config)

window.addEventListener('reload-azurelogin', () => {
  ReactDOM.createRoot(document.getElementById('azure-login-root') as HTMLElement).render(
    <React.StrictMode>
      <App msalInstance={msalInstance} />
    </React.StrictMode>
  )
})

ReactDOM.createRoot(document.getElementById('azure-login-root') as HTMLElement).render(
  <React.StrictMode>
    <App msalInstance={msalInstance} />
  </React.StrictMode>
)
